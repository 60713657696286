import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184')];

export const server_loads = [];

export const dictionary = {
	"/": [2],
	"/10-common-mistakes-when-outsourcing-the-software-development": [3],
	"/10-lightweight-bootstrap-alternatives": [4],
	"/10-reasons-to-outsource-the-software-development": [5],
	"/11-most-popular-alternatives-to-wordpress": [6],
	"/11-outstanding-free-travel-apps": [7],
	"/12-startup-tools-you-should-know-about": [8],
	"/5-things-you-need-to-automate-in-your-business": [9],
	"/5-tips-for-outsourcing-product-development": [10],
	"/6-tips-for-managing-an-outsourced-team": [11],
	"/7-advantages-of-developing-user-personas-prior-to-app-development": [12],
	"/7-amazing-project-management-tools": [13],
	"/7-mobile-app-development-trends": [14],
	"/7-things-to-take-into-account-before-launching-a-startup": [15],
	"/about-us": [16],
	"/android-vs-ios-development-pros-cons": [17],
	"/android-vs-ios-where-to-start-building-your-app": [18],
	"/angular-development-services": [19],
	"/angular-pros-and-cons": [20],
	"/approach": [21],
	"/author/ivan": [22],
	"/author/oleg": [23],
	"/basics-of-developing-on-demand-service-app": [24],
	"/benefits-of-telehealth-for-patients-doctors": [25],
	"/best-logistics-software": [26],
	"/blog": [27],
	"/career": [28],
	"/career/automation-qa-engineer": [29],
	"/career/content-writer": [30],
	"/career/middle-senior-golang-developer": [31],
	"/career/senior-net-developer": [32],
	"/choosing-a-technology-stack-for-web-application-development": [33],
	"/construction-app-development-guide": [34],
	"/construction-industry-technology-trends": [35],
	"/construction-software-development": [36],
	"/contact-us": [37],
	"/cookie-policy": [38],
	"/cost-of-telemedicine-software": [39],
	"/creating-an-online-store-hosted-vs-self-hosted-ecommerce-platform": [40],
	"/crm-software-for-logistics": [41],
	"/custom-crm-development-services": [42],
	"/dedicated-development-team": [43],
	"/design-thinking-approach-in-software-development": [44],
	"/desktop-or-web-application-what-to-develop": [45],
	"/detailed-guide-on-building-enterprise-app": [46],
	"/development-team-for-startup": [47],
	"/dynamic-route-planning-and-optimization-explained": [48],
	"/education-software-development-services": [49],
	"/ehr-for-patient-care": [50],
	"/ehr-system-development-process": [51],
	"/electronic-medical-records-software-development": [52],
	"/enterprise-application-development-services": [53],
	"/enterprise-digital-transformation-best-practices": [54],
	"/erp-development-services": [55],
	"/erp-for-construction-industry-and-infrastructure": [56],
	"/event-apps-as-a-growing-trend-in-the-app-development": [57],
	"/finance-software-development": [58],
	"/fleet-management-app-development": [59],
	"/fleet-management-software-development-company": [60],
	"/flutter-app-development-services": [61],
	"/flutter-pros-and-cons-for-app-development": [62],
	"/freight-forwarding-software-solutions": [63],
	"/functional-non-functional-requirements": [64],
	"/healthcare-app-development-process": [65],
	"/healthcare-crm-software": [66],
	"/healthcare-mobile-app-trends": [67],
	"/healthcare-mobile-apps-which-make-a-change": [68],
	"/healthcare-of-the-future": [69],
	"/healthcare-software-development": [70],
	"/healthcare-website-design": [71],
	"/hipaa-compliant-telehealth-platform": [72],
	"/how-much-it-costs-to-develop-an-app-like-uber": [73],
	"/how-to-build-a-mobile-banking-app": [74],
	"/how-to-build-a-product-from-scratch": [75],
	"/how-to-build-startup-from-scratch": [76],
	"/how-to-create-a-messaging-app-like-whatsapp": [77],
	"/how-to-create-laboratory-information-management-system": [78],
	"/how-to-implement-gpt-into-your-app": [79],
	"/how-to-make-agile-and-outsourcing-work": [80],
	"/how-to-manage-software-development-team": [81],
	"/how-to-start-a-mobile-dating-app": [82],
	"/how-uber-freight-is-changing-the-freight-forwarding-market": [83],
	"/human-resource-management-software": [84],
	"/icons": [85],
	"/java-development-services": [86],
	"/laboratory-information-management-systems": [87],
	"/logistics-software-development": [88],
	"/make-entity-framework-10x-faster-in-20-minutes": [89],
	"/make-gps-app-android-ios": [90],
	"/medtech-trends": [91],
	"/mobile-app-architecture": [92],
	"/mobile-development": [93],
	"/mobility-software-development": [94],
	"/mvp-vs-mcp-what-to-choose-for-your-project": [95],
	"/native-vs-cross-platform-development": [96],
	"/net-software-development-company": [97],
	"/net-vs-node-js": [98],
	"/not-found": [99],
	"/online-payment-systems-which-way-to-go": [100],
	"/our-clients": [101],
	"/our-team": [102],
	"/our-works": [103],
	"/our-works/agriculture-software-solutions": [104],
	"/our-works/audit-management-system": [105],
	"/our-works/bridgestone-car-park-survey": [106],
	"/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics": [107],
	"/our-works/construction-supply-chain-automation": [108],
	"/our-works/custom-telemedicine-solution": [109],
	"/our-works/data-management-tool-for-tax-offices": [110],
	"/our-works/digital-solution-for-veteran-rehabilitation-center": [111],
	"/our-works/emergency-notifier": [112],
	"/our-works/ev-charging-software": [113],
	"/our-works/fee-foundation-of-economic-education": [114],
	"/our-works/formula-compounder": [115],
	"/our-works/freelance-marketplace-development": [116],
	"/our-works/health-metrics": [117],
	"/our-works/hostoo": [118],
	"/our-works/lab-web-portal": [119],
	"/our-works/land-tax-software": [120],
	"/our-works/logistics-web-mobile-software": [121],
	"/our-works/medical-information-system-software": [122],
	"/our-works/migration-to-multi-tenant-saas-platform": [123],
	"/our-works/mind-your-service-mobile-gps-tracker": [125],
	"/our-works/mind-your-service": [124],
	"/our-works/network-deposition-services": [126],
	"/our-works/qa-services-for-bank": [127],
	"/our-works/quarry-automation-platform": [128],
	"/our-works/road-trip-mobile-app": [129],
	"/our-works/saas-based-resident-experience-platform-for-senior-living": [130],
	"/our-works/sales-assistant-software": [131],
	"/our-works/sales-crm-for-education": [132],
	"/our-works/scan-tool": [133],
	"/our-works/scom-health-check-v2": [134],
	"/our-works/sloper-climbing-app": [135],
	"/our-works/std-status-app": [136],
	"/our-works/time-booking-tool": [137],
	"/our-works/travel-management-mobile-app": [138],
	"/our-works/wells-up": [139],
	"/our-works/working-bicycle": [140],
	"/pharmacy-app-development-process": [141],
	"/platform-development": [142],
	"/privacy-policy": [143],
	"/product-development-services": [144],
	"/pros-and-cons-of-xamarin-app-development": [145],
	"/pros-cons-net-development": [146],
	"/quality-assurance": [147],
	"/reactjs-development-services": [148],
	"/software-development-for-startups": [149],
	"/software-development-methodologies-pros-cons": [150],
	"/software-outsourcing-ukraine-hire-software-developers": [151],
	"/supply-chain-management-software-features-requirements": [152],
	"/support-and-maintenance": [153],
	"/system-development-life-cycle": [154],
	"/team-extension-services": [155],
	"/technology-for-elderly-care-services": [157],
	"/technology": [156],
	"/telemedicine-software-development": [158],
	"/the-pillars-of-a-winning-telehealth-strategy": [159],
	"/the-role-of-a-cto-in-a-startup-mission-responsibilities-and-skills": [160],
	"/time-and-materials-vs-fixed-price-vs-scope-based-model-choosing-the-right-pricing-model-for-your-project": [161],
	"/top-6-logistics-startups-to-watch-out": [162],
	"/top-9-wireframing-and-prototyping-tools": [163],
	"/top-javascript-frameworks-for-front-end-development": [164],
	"/top-web-development-trends-you-must-know-about": [165],
	"/trucking-app-development-guide": [166],
	"/ui-ux-design-services": [167],
	"/ui-vs-ux": [168],
	"/vue-js-development-services": [169],
	"/warehouse-management-system-wms": [170],
	"/web-app-architecture-types-components-trends": [171],
	"/web-development": [172],
	"/what-are-the-characteristics-of-successful-startups": [173],
	"/what-can-you-do-with-java": [174],
	"/what-is-a-lims": [175],
	"/what-is-human-centered-design": [176],
	"/what-is-last-mile-delivery-technology": [177],
	"/what-is-no-code-development": [178],
	"/what-is-samd-all-about-software-as-a-medical-device": [179],
	"/what-is-warehouse-management-system": [180],
	"/what-you-need-to-know-about-building-mvp": [181],
	"/why-a-fixed-price-contract-is-a-bad-idea-for-your-project": [182],
	"/why-every-logistics-business-must-go-mobile": [183],
	"/xamarin-app-development-services": [184]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};